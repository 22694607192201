<template>
  <div class="taxraising increment-wrap">
    <div class="line"></div>
    <h2>税筹服务</h2>
    <div class="img">
      <div class="title">税筹服务</div>
      <p class="text">
        解决个体司机分散，代开税票困难的难题，减少企业缴纳所得税，<br />
        为个体司机提供合规发票，减少税务风险，避免断税
      </p>
      <img src="./../../assets/img/6.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
</style>